(function(window, document, $) {

	// eslint-disable-next-line max-len
	var $menus = $('.elementor-semipro-nav--wrapper:not(.elementor-menu--dropdown-desktop), .elementor-semipro-nav--wrapper.elementor-semipro-nav--layout-dropdown');

	$menus.each(function(idx, menu) {
		var $menu = $(menu);

		var
			body = document.querySelector('body'),
			nav = $menu.find('.nav')[0],
			navBar = $menu.find('.nav--bar')[0],
			navToggle = $menu.find('.nav--toggle')[0],
			navMenuIsOpen = false,
			navMenu = $menu.find('.nav--menu')[0],
			navMenuBG = $menu.find('.nav--menu-bg')[0];

		// TODO: scroll debounce
		var windowHeight = window.innerHeight,
			navBarHeight = navBar.offsetHeight,
			windowNavOffset = windowHeight - navBarHeight;

		// ----- FUNCTIONS ----- //

		// nav
		function showNav() {
			// TODO :: need to completely hide all other menus
			navMenuIsOpen = true;

			$(body).addClass('elementor-semipro-nav--is-visible');
			$(navBar).parent().addClass('active');
			$(navToggle).addClass('active');
			$(navMenu).addClass('active');
			$(navMenuBG).addClass('active');
		}

		function hideNav() {
			navMenuIsOpen = false;

			$(body).removeClass('elementor-semipro-nav--is-visible');
			$(navBar).parent().removeClass('active');
			$(navToggle).removeClass('active');
			$(navMenu).removeClass('active');
			$(navMenuBG).removeClass('active');
		}

		function debounce(fn) {
			// Setup a timer
			var timeout;

			// Return a function to run debounced
			return function() {
				// Setup the arguments
				var context = this;
				var args = arguments;

				// If there's a timer, cancel it
				if (timeout) {
					window.cancelAnimationFrame(timeout);
				}

				// Setup the new requestAnimationFrame()
				timeout = window.requestAnimationFrame(function() {
					fn.apply(context, args);
				});
			};
		}

		// resize
		var resizeDebounce = debounce(function() {
			hideNav();
		}, 250);

		// ----- EVENT HANDLERS ----- //

		// nav
		navToggle.addEventListener('click', function(e) {
			!navMenuIsOpen ? showNav() : hideNav();
		});

		// keyboard controls
		document.addEventListener('keydown', function(e) {
			var
				keyboardKeyCode = e.keyCode,
				keyboardKey = e.key;

			switch (keyboardKeyCode) {
			case 27: // esc
				if (navMenuIsOpen) hideNav();
				break;
			default:
				break;
			}
		});

		// show navbar on page scroll
		document.addEventListener('scroll', function() {
			var scrollPosition = window.pageYOffset;

			if (scrollPosition > windowNavOffset) {
				$(nav).addClass('nav--solid');
			} else {
				$(nav).removeClass('nav--solid');
			}
		});

		// resize
		window.addEventListener('resize', resizeDebounce);

		// enable hover on touch
		document.addEventListener('touchstart', function() {}, true);
	});


})(window, window.document, jQuery);
